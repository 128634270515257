var render = function () {
  var _vm$isError;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "activity text-left mb-6"
  }, [_c('div', {
    staticClass: "flex justify-between items-start mb-2 w-7/10"
  }, [_c('div', [_c('p', {
    staticClass: "text-base font-bold mb-3 text-neutral-500"
  }, [_vm._v("Activity " + _vm._s(_vm.index + 1))]), _c('div', {
    staticClass: "flex gap-6"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isTemplateAct,
      expression: "!isTemplateAct"
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.isIndependent,
      expression: "data.isIndependent"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": 'independent_parent' + _vm.index
    },
    domProps: {
      "checked": Array.isArray(_vm.data.isIndependent) ? _vm._i(_vm.data.isIndependent, null) > -1 : _vm.data.isIndependent
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.isIndependent,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "isIndependent", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "isIndependent", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "isIndependent", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": 'independent_parent' + _vm.index
    }
  }, [_vm._v("Independent Activity")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isTemplateAct,
      expression: "!isTemplateAct"
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.isPostTest,
      expression: "data.isPostTest"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": 'post_test_checkbox' + _vm.index
    },
    domProps: {
      "checked": Array.isArray(_vm.data.isPostTest) ? _vm._i(_vm.data.isPostTest, null) > -1 : _vm.data.isPostTest
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.data.isPostTest,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.data, "isPostTest", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.data, "isPostTest", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.data, "isPostTest", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": 'post_test_checkbox' + _vm.index
    }
  }, [_vm._v("Mark as Post-Test")])])]), _c('p', {
    staticClass: "text-xs pl-4 ml-2 text-neutral-400 mb-2"
  }, [_vm._v("Independent activity can be accessed immediately without waiting for previous activity")])]), _vm.index !== 0 ? _c('div', {
    staticClass: "flex justify-center items-center mt-1 mr-5"
  }, [_c('button', {
    staticClass: "mr-2 p-1 border-1 rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-yellow-primary border-yellow-primary hover:bg-yellow-300 focus:outline-none",
    class: [_vm.isFirstIndex && _vm.enableFirstAct ? 'bg-grey-field hover:bg-grey-field cursor-not-allowed' : ''],
    attrs: {
      "disabled": _vm.isFirstIndex && _vm.enableFirstAct
    },
    on: {
      "click": function click($event) {
        return _vm.changePosition('up', _vm.index);
      }
    }
  }, [_c('Chevron', {
    attrs: {
      "direction": "up",
      "height": "16",
      "width": "16",
      "color": "#FFF"
    }
  })], 1), _c('button', {
    staticClass: "p-1 border-1 rounded transition-all outline-none inline-flex justify-center items-center gap-2 bg-yellow-primary border-yellow-primary hover:bg-yellow-300 focus:outline-none",
    class: [_vm.isLastIndex ? 'bg-grey-field hover:bg-grey-field cursor-not-allowed' : ''],
    attrs: {
      "disabled": _vm.isLastIndex
    },
    on: {
      "click": function click($event) {
        return _vm.changePosition('down', _vm.index);
      }
    }
  }, [_c('Chevron', {
    attrs: {
      "direction": "down",
      "height": "16",
      "width": "16",
      "color": "#FFF"
    }
  })], 1)]) : _vm._e(), _c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.index !== 0 || _vm.enableFirstAct,
      expression: "index !== 0 || enableFirstAct"
    }],
    attrs: {
      "type": "secondary",
      "size": "icon",
      "icon": function icon() {
        return import(
        /* webpackChunkName: 'icon' */
        '@/components/Icons/Trash');
      }
    },
    on: {
      "action": function action($event) {
        return _vm.deleteItem(_vm.index);
      }
    }
  })], 1), _c('div', {
    staticClass: "p-8 border rounded-lg w-7/10"
  }, [_c('p', {
    staticClass: "text-sm font-medium mb-2 text-neutral-500"
  }, [_vm._v("Activity Title")]), _c('div', {
    staticClass: "bg-neutral-100 p-4 rounded-lg mb-6",
    class: {
      'is-invalid': (_vm$isError = _vm.isError) === null || _vm$isError === void 0 ? void 0 : _vm$isError.error
    }
  }, [_c('div', {
    staticClass: "w-3/5 mb-4"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "isLabelRequire": "",
      "label": "Title",
      "borderEnabled": true,
      "placeholder": "Activity Title"
    },
    model: {
      value: _vm.data.title,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "title", $$v);
      },
      expression: "data.title"
    }
  })], 1)]), _vm._l(_vm.data.activityItems, function (child, childIndex) {
    var _vm$isError2;

    return _c('div', {
      key: 'child-' + childIndex,
      staticClass: "child"
    }, [_c('ChildActivityForm', {
      ref: 'childActivity' + childIndex,
      refInFor: true,
      attrs: {
        "isTemplateAct": _vm.isTemplateAct,
        "isLastIndex": childIndex === _vm.data.activityItems.length - 1 ? true : false,
        "data": child,
        "index": childIndex,
        "parentIndex": _vm.index,
        "error": _vm.childError && _vm.childError[childIndex],
        "classId": _vm.classId,
        "primaryInstructor": _vm.primaryInstructor,
        "initialInstructorList": _vm.initialInstructorList,
        "selectedWorkflow": _vm.selectedWorkflow,
        "isError": (_vm$isError2 = _vm.isError) === null || _vm$isError2 === void 0 ? void 0 : _vm$isError2.activityItems[childIndex]
      },
      on: {
        "changePositionChild": function changePositionChild($event) {
          return _vm.changePositionChild($event, _vm.index);
        },
        "deleteChild": function deleteChild($event) {
          return _vm.deleteChild($event, _vm.index);
        },
        "deleteInstructorActivityItem": _vm.deleteInstructorActivityItem,
        "onChange": function onChange($event) {
          return _vm.$emit('onChange');
        }
      }
    })], 1);
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Add New Child Activity",
      "type": "tertiary",
      "additionalClass": "bg-white"
    },
    on: {
      "action": function action($event) {
        return _vm.addMoreChild();
      }
    }
  })], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }